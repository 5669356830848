<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
    <v-row dense>
      <v-col cols="12" class="pa-0">
        <v-card>
          <v-toolbar color="color2 color2Text--text" class="mx-auto" :dark="color1IsDark">
            <v-toolbar-title v-if="isMounted && $refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="color1Text" text icon @click.stop="userFilter = !userFilter" v-if="false">
              <v-icon>fas fa-filter</v-icon>
            </v-btn>
          </v-toolbar>
          <loading-bar :value="loading"></loading-bar>
          <v-expand-transition>
            <v-row dense  v-if="rowHeaderAds">
              <v-col cols="12" class="d-flex justify-center">
                <div class="mr-2" style="height: 75px; width: 395px;" v-for="ad in rowHeaderAds" :key="ad.id">
                  <ad-card :ad="ad" :flat="true" :rowHeader="true"></ad-card>
                </div>
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-card-text class="pt-0">
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="prev"
                >
                  <v-icon small>
                    fas fa-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="next"
                >
                  <v-icon small>
                    fas fa-chevron-right
                  </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar && false">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu
                  bottom
                  right
                  v-if="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      color="grey darken-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right>
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = '4day'">
                      <v-list-item-title>4 days</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="600">
              <v-calendar
                ref="calendar"
                color="color2 color2Text--text"
                v-model="focus"
                :type="type"
                :events="events"
                :event-color="getEventColor"
                @click:event="showEvent"
              ></v-calendar>
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
                :right="selectedSide === 'r'"
                :left="selectedSide === 'l'"
              >
                <tournament-card
                  :event="selectedEvent"
                ></tournament-card>
              </v-menu>
            </v-sheet>
          </v-card-text>
          <v-card-actions class="justify-end" v-intersect="onIntersect">
            <organization-calendar-event-create-drawer
              ref="drawer"
              :isIntersecting="isIntersecting"
              :myEvent="selectedEvent"
            ></organization-calendar-event-create-drawer>
            <v-btn
              v-if="false"
              color="color3 color3Text--text"
              fab right
              :fixed="!isIntersecting"
              :bottom="!isIntersecting"
            >
              <v-icon>fas fa-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="text-right" cols="12" >
        <v-btn color="color3" text icon @click.stop="getList">
          <v-icon>fas fa-sync-alt</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay color="white" v-if="loading">
      <div class="text-center">
        <v-progress-circular indeterminate color="color3" size="64"></v-progress-circular>
      </div>
      <div class="color3--text text-center text-h4">Loading</div>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Summary from '@/classes/TournamentSummary'
import flatten from '../helpers/ArrayFlatten'
const OrganizationCalendarEventCreateDrawer = () => import('./OrganizationCalendarEventCreateDrawer')
const TournamentCard = () => import('@/components/Tournament/TournamentCard')

export default {
  props: ['username'],
  data () {
    return {
      loading: false,
      activeTab: null,
      ads: [],
      calendars: [],
      search: null,
      listView: false,
      userFilter: true,
      geoPosition: null,
      gettingLocation: false,
      location: null,
      gError: null,
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days'
      },
      isMounted: false,
      isIntersecting: false,
      summaries: [],
      selectedEvent: null,
      selectedElement: null,
      selectedOpen: false,
      selectedSide: 'r'
    }
  },
  computed: {
    ...mapGetters(['color1IsDark', 'theme']),
    events () {
      return flatten(this.summaries.map(m => m.calenderEvents))
    },
    rowHeaderAds () {
      return this.ads.filter(f => f.rowHeader)
    }
  },
  methods: {
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    getAds () {
      this.$http.get(`/ads?username=${this.subdomain}`)
        .then(r => {
          this.ads = r.data.map(m => JSON.parse(m)).filter(f => f.locations && f.locations.includes('events'))
        })
    },
    getCalendars () {
      this.loading = true
      // this.$VBL.organization
    },
    getEventColor (event) {
      return this.theme.color3
    },
    onIntersect (entries) {
      this.isIntersecting = entries[0].isIntersecting
    },
    getEvents () {
      this.loading = true
      const query = `active=false&organizationUserName=${this.username}&withCounts=true&coOp=${this.coOp}`
      this.$VBL.tournament.getAllSummaries(query)
        .then(r => {
          this.summaries = r.data.map(s => new Summary(s))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    showEvent ({ nativeEvent, event, day }) {
      this.selectedSide = day.weekday < 4 ? 'r' : 'l'
      const open = () => {
        const sum = this.summaries.find(f => f.id === event.id)
        this.selectedEvent = sum
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true }))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    showEventDrawer (e) {
      console.log(e)
      const sum = this.summaries.find(f => f.id === e.event.id)
      this.selectedEvent = sum
      this.$refs.drawer.open()
    }
  },
  mounted () {
    this.isMounted = true
    this.getEvents()
  },
  components: {
    OrganizationCalendarEventCreateDrawer,
    TournamentCard
  }
}
</script>
